import * as React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Seo from '../components/seo'
import Video from '../components/video'
import { convertDate } from '../utilities/convert-date'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  justify-content: center;

  @media (min-width: 768px) {
    max-width: 900px;
    padding: 2rem;
  }
`
const Header = styled.header`
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.accent};
  padding: 2rem 1rem 1rem 1rem;
`
const Date = styled.p`
  font-size: 0.5em;
  font-weight: 400;
  text-transform: none;
  color: ${({ theme }) => theme.contrast};
  margin-bottom: 1.5rem;
`
const Description = styled.p`
  font-size: 0.75em;
  line-height: 1.25em;
  padding: 1rem;
`
const VideoPlayer = ({ data }) => {
  const videoData = data.youtubeVideo

  return (
    <Container>
      <Seo title={videoData.title} description={videoData.description} />
      <Header>
        <h1>{videoData.title}</h1>
        <Date>Published: {convertDate(videoData.publishedAt)}</Date>
      </Header>
      <Video title={videoData.title} videoId={videoData.videoId} />
      <Description>{videoData.description}</Description>
    </Container>
  )
}

export default VideoPlayer

export const pageQuery = graphql`
  query VideoById($id: String!) {
    youtubeVideo(videoId: { eq: $id }) {
      title
      description
      publishedAt
      videoId
    }
  }
`
