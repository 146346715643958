const months = ['January','February','March','April','May','June','July',
'August','September','October','November','December'];

export const convertDate = (isoFormat) => {
  let date = new Date(isoFormat);
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();

  if (day < 10) {
    day = '0' + day;
  }

  return `${months[month]} ${day}, ${year} `
}